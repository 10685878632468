/**  Aide de concert avec native lazyload pour ne charger les secondes images des cartes seulement au hover **/

const containers = document.querySelectorAll(".card");

containers.forEach((f) =>
  f.addEventListener("mouseenter", function () {
    var div = f.querySelector("picture + picture");

    div.classList.remove("cardhidden");

    const handleImageLoad = () => {
      div.classList.add("cardvisible");
      image.removeEventListener("load", handleImageLoad);
    };

    const image = f.querySelector("picture + picture > img");
    if (!image.complete) {
      image.addEventListener("load", handleImageLoad);
      image.addEventListener("error", handleImageLoad);
    }
  })
);
